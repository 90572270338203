import {
  tfGet,
  tfPut,
  tfPost,
} from '@/utils/services.js';
import { config } from '@/utils/config.js';

/**
 * API services for account related services.
 * The store dispatches/commits and commits from these methods are currently possible due to how these methods are passed to the shared repo.
 *
 * @vuedoc
 * @exports account
 * @category Services
 */

/**
 * Clear any storage and sign the user out.
 *
 * @returns {Promise}
 */
export function signOut() {
  return tfPost('sign_out');
}

/**
 * Changing the users password.
 *
 * @param {string} password
 * @returns {Promise}
 */
export function changePassword(password) {
  return tfPut('reset_password', { password });
}

/**
 * Sending the user a new temp password.
 *
 * @param {object} payload
 * @param {string} payload.email
 * @returns {Promise}
 */
export function passwordReset({ email }) {
  return tfPost('forgot_password', { email });
}

/**
 * Get the invitation from the api.
 *
 * @param {string} token
 * @returns {Promise}
 */
export function getInvite(token) {
  return tfGet(`users/invitation/${token}`);
}

/**
 * Get the user info based on the user id in the cookies.
 *
 * @param {object} user
 * @returns {Promise}
 */
export function register(user = {}) {
  return tfPost(`users/invitation/${user.invitation_token}`, user);
}

/**
 * Sign the user in.
 *
 * @param {object} payload
 * @returns {object}
 */
export async function signIn(payload = {}) {
  return tfPost('sign_in', payload);
}

/**
 * Sign the user in using a Google Auth code.
 *
 * @param {string} code
 * @returns {object}
 */
export async function getGoogleOauthSession(code) {
  return tfPost(`${config.VUE_APP_API_URL}/v1/omniauth/google/callback`, { code });
}

/**
 * Get the provider for the provided email address.
 *
 * @param {string} email
 * @param {boolean} flag
 * @returns {object}
 */
export async function getUserProvider(email, flag) {
  const encodedEmail = encodeURIComponent(email);
  const redirect = window.location.search.match(/redirect=([^&]*)/);
  // rff: workOsBrokerUi https://watchtower.atlassian.net/browse/LC-1988
  const portalParam = flag ? '&portal=broker' : '';
  const envParam = config.VUE_APP_ENV === 'development' ? `&url=${window.location.origin}/sso` : '';
  const redirectParam = redirect ? `&redirect=${redirect[1]}` : '';

  return tfGet(`${config.VUE_APP_API_URL}/v1/users/provider?email=${encodedEmail}${portalParam}${envParam}${redirectParam}`);
}

/**
 * Gets current user's information.
 *
 * @param {string} authToken
 * @returns {Promise}
 */
export async function getCurrentUser(authToken) {
  const headers = authToken ? { headers: { 'X-API-AUTHTOKEN': `${authToken}` } } : {};

  return tfGet(`${config.VUE_APP_API_URL}/v1/users/me`, headers);
}
